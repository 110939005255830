<template>
  <div class="revise-comp-contaner">
    <p class="title">修改登录密码</p>
    <div class="img-box">
      <p><img src="@/assets/images/home/success.png" alt="" /></p>
      <p class="f14 c3">修改成功</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "YoufujichuangReviseComp",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.revise-comp-contaner {
  padding: 32px;
  .title {
    padding-bottom: 16px;
    border-bottom: 1px solid #eeeeee;
    font-size: 22px;
    font-weight: bold;
    color: #333333;
  }
  .img-box {
    margin-top: 207px;
    img {
      width: 80px;
      height: 80px;
      margin-bottom: 12px;
    }
    p {
      text-align: center;
    }
  }
}
</style>